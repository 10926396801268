import React from "react";
import "./App.css";

export default function App() {
  return (
    <div className="App">
      <img
        className="Header"
        src={"/assets/website-header.png"}
        alt={"News Hour UK Header"}
      />
      <div className="Headline">
        Dambury: Search for missing woman continues
      </div>
      <div className="Subheading">2 hours ago</div>
      <img
        className="Jane"
        src={"/assets/jane-edit.png"}
        alt={"Jane Tailor: Missing Person"}
      />
      <div className="Subheading">
        Jane Tailor was last seen in Dambury Hospital last night.
      </div>
      <div className="Paragraph">
        30 year old Jane Tailor, an admin assistant, was last seen leaving
        Dambury Hospital against the advice of doctors and has not been seen
        since. Police have asked the public to report any sightings immediately
        but also advised the public to stay safe and avoid confrontation.
      </div>
      <div className="Paragraph">
        Dambury police representatives refused to comment on whether Tailor's
        disappearance could be another example of the unprecidented increase in
        violence seen in Dambury. The Tailor family have been left extremely
        concerned. Speaking at a small press conference this morning, Jane's
        mother thanked her neighbours for assisting with the search "dispite
        everything that is going on at the moment". Law inforcement reported
        making "a significant start" on the investigation but have yet to make
        any arrests.
      </div>
    </div>
  );
}
